import { getText, userLocale } from "../locales/initI18n";

import DateHelper from "./DateHelper";
import { Flight } from "../models/Flight";
import { FlightFilterEnum } from "../models/enums/FlightFilterEnum";
import { FlightTimeFliterEnum } from "../models/enums/FlightTimeFliterEnum";
import { FlightType } from "../models/enums/FlightType";
import { PigeonSexEnum } from "../models/enums/PigeonSexEnum";
import { distance } from "./utils";

export default class Formatter {
  static UNITS = {
    Temperature: "°C",
    Pressure: "hPa",
    Humidity: "%",
    WindSpeed: "km/h",
    WindDirections: ["N", "NE", "E", "SE", "S", "SW", "W", "NW"],
    Distance: "km",
    Liquid: "mm",
    Visibility: "km",
  } as const;
  //#region publics
  public static Liquid(liquid?: number | null) {
    const unit = Formatter.UNITS.Liquid;
    if (liquid === undefined || liquid === null) return "-";
    return `${(liquid * 1000).toFixed(1)}${unit}`;
  }
  public static WindSpeed(windSpeed?: number | null, kmh = true) {
    const unit = Formatter.UNITS.WindSpeed;
    if (windSpeed === undefined || windSpeed === null) return "-";
    if (!kmh) return `${Formatter.msToKmh(windSpeed).toFixed(2)}${unit}`;
    return `${windSpeed.toFixed(2)}${unit}`;
  }
  public static WindDirection(windDirection?: number | null) {
    return Formatter.degToCompass(windDirection);
  }
  public static WindSpeedAndDirection(
    windSpeed?: number | null,
    windDirection?: number | null
  ) {
    return `${Formatter.WindDirection(windDirection)}, ${Formatter.WindSpeed(
      windSpeed
    )}`;
  }
  public static Temperature(temperature?: number | null) {
    const unit = Formatter.UNITS.Temperature;
    return temperature ? `${temperature.toFixed(2)}${unit}` : "-";
  }
  public static Distance = (distance?: number | string | null) => {
    const unit = this.UNITS.Distance;
    if (!distance) return "-";
    return `${distance}${unit}`;
  };
  public static Pressure = (pressure?: number | null) => {
    const unit = this.UNITS.Pressure;
    if (!pressure) return "-";
    return `${pressure.toFixed(2)} ${unit}`;
  };
  public static Humidity = (humidity?: number | null) => {
    const unit = this.UNITS.Humidity;
    if (!humidity) return "-";
    return `${humidity.toFixed(2)}${unit}`;
  };
  public static Visibility = (visibility?: number | null) => {
    const unit = this.UNITS.Visibility;
    if (!visibility) return "-";
    return `${visibility.toFixed(2)}${unit}`;
  };
  public static Date(date?: Date | string | null) {
    if (!date) return "-";
    return DateHelper.getFormattedDate(date) || "";
  }
  public static DateTime(date?: Date | string | null) {
    if (!date) return "-";
    return DateHelper.getFormattedDateTime(date) || "";
  }
  public static Year(year?: Date | string | null | number) {
    if (!year) return "-";
    if (typeof year === "number") year = year.toString();
    const date = DateHelper.toDate(year);
    if (isNaN(date.getTime())) return "-";

    return date.getFullYear().toString();
  }
  public static TypeName(flight: Flight, tab?: FlightFilterEnum): string {
    switch (tab) {
      case FlightFilterEnum.Shared: {
        return getText("FlightList.TypeFilterFriends");
      }
    }
    switch (flight.type) {
      case FlightType.Open:
        return getText("FlightList.tab.event");
      case FlightType.Single: {
        if (flight.relatedFlights.length > 0) {
          return getText("Prywatny konkurs");
        }
        if (!!flight.parentFlight) {
          return Formatter.TypeName(flight.parentFlight);
        }
        return getText("FlightList.tab.private");
      }
    }
  }
  public static Duration(duration: number, startDate?: Date): string;
  public static Duration(duration: number): string;
  public static Duration(duration: number, startDate?: Date) {
    if (!startDate) {
      const hours = Math.floor(duration / 60);
      const minutes = duration % 60;
      return `${hours}h ${minutes}m`;
    }
    const endDate = new Date(startDate);
    endDate.setMilliseconds(duration);
    return Formatter.DateTime(endDate);
  }
  public static getFlightTableContentName(
    tab: FlightFilterEnum,
    timeFilter: FlightTimeFliterEnum,
    search?: string
  ) {
    const text = Formatter.getFlightTableContentNameInternal(tab, timeFilter);
    if (!search) return text;
    return `${text} ${getText("FlightList.WithSearch")} "${search}"`;
  }
  public static PigeonSex(sex: PigeonSexEnum) {
    switch (sex) {
      case PigeonSexEnum.Male:
        return "1";
      case PigeonSexEnum.Female:
        return "0";
      default:
        return "-";
    }
  }
  //#endregion
  //#region privates
  private static msToKmh(ms: number) {
    return ms * 3.6;
  }
  private static degToCompass(degrees: number | null | undefined) {
    if (!degrees) return "-";
    const directions = Formatter.UNITS.WindDirections;

    const directionIndex = Math.round((degrees % 360) / 45);

    return directions[directionIndex - 1] || "-";
  }
  private static getFlightTableContentNameInternal(
    tab: FlightFilterEnum,
    timeFilter: FlightTimeFliterEnum
  ) {
    switch (tab) {
      case FlightFilterEnum.InternalOpen: {
        switch (timeFilter) {
          case FlightTimeFliterEnum.All: {
            return getText("FlightList.AllGroupFlights");
          }
          case FlightTimeFliterEnum.Present: {
            return getText("FlightList.PresentInternalGroupFlights");
          }
          case FlightTimeFliterEnum.Future: {
            return getText("FlightList.FutureInternalGroupFlights");
          }
          case FlightTimeFliterEnum.History: {
            return getText("FlightList.HistoryInternalGroupFlights");
          }
        }
        break;
      }
      case FlightFilterEnum.Open: {
        switch (timeFilter) {
          case FlightTimeFliterEnum.All: {
            return getText("FlightList.AllCompetitionFlights");
          }
          case FlightTimeFliterEnum.Present: {
            return getText("FlightList.PresentCompetitionFlights");
          }
          case FlightTimeFliterEnum.Future: {
            return getText("FlightList.FutureCompetitionFlights");
          }
          case FlightTimeFliterEnum.History: {
            return getText("FlightList.HistoryCompetitionFlights");
          }
        }
        break;
      }
      case FlightFilterEnum.Private: {
        switch (timeFilter) {
          case FlightTimeFliterEnum.All: {
            return getText("FlightList.AllPrivateFlights");
          }
          case FlightTimeFliterEnum.Present: {
            return getText("FlightList.PresentPrivateFlights");
          }
          case FlightTimeFliterEnum.Future: {
            return getText("FlightList.FuturePrivateFlights");
          }
          case FlightTimeFliterEnum.History: {
            return getText("FlightList.HistoryPrivateFlights");
          }
        }
        break;
      }
      case FlightFilterEnum.Shared: {
        switch (timeFilter) {
          case FlightTimeFliterEnum.All: {
            return getText("FlightList.AllSharedFlights");
          }
          case FlightTimeFliterEnum.Present: {
            return getText("FlightList.PresentSharedFlights");
          }
          case FlightTimeFliterEnum.Future: {
            return getText("FlightList.FutureSharedFlights");
          }
          case FlightTimeFliterEnum.History: {
            return getText("FlightList.HistorySharedFlights");
          }
        }
        break;
      }
    }
  }
  //#endregion
}
