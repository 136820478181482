import {
  Button,
  Input,
  Table,
  TableCell,
  TableGrowing,
  TableHeaderCell,
  TableHeaderRow,
  TableRow,
} from "@ui5/webcomponents-react";
import { HBox, VBox } from "../styles/Flexbox.styled";
import { addRing, getRings } from "../services/RingService";
import { useEffect, useState } from "react";

import Formatter from "../utils/Formatter";
import HalfModal from "./HalfModal/HalfModal";
import { ModalProps } from "./modals/IModalOptions";
import PageOptions from "../models/PageOptions";
import { getText } from "../locales/initI18n";
import { setError } from "../redux/modules/error";
import { useAppDispatch } from "../redux/hooks";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useTheme } from "styled-components";
import useUser from "../hooks/useUser";

const options = new PageOptions();
const RingDialog = (props: ModalProps<unknown>) => {
  const [addRingNumber, setAddRingNumber] = useState("");
  const [searchRingNumber, setSearchRingNumber] = useState("");
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const user = useUser();
  const [rawFilters, setRawFilters] = useState<string>("");
  const { data, fetchNextPage, refetch } = useInfiniteQuery({
    queryKey: new PageOptions({ filters: rawFilters }).getQueryKey("rings"),
    queryFn: ({ pageParam = 1 }) =>
      getRings(new PageOptions({ filters: rawFilters, page: pageParam })),
    getNextPageParam: options.nextPagefn,
  });
  const lastMeta = data?.pages[data.pages.length - 1].meta;
  const rings = data?.pages.flatMap((page) => page.data);

  const onAddRing = async () => {
    try {
      await addRing(addRingNumber);
      setAddRingNumber("");
      refetch();
    } catch (error) {
      dispatch(setError(error));
    }
  };

  const onSearchRing = async () => {
    setRawFilters(
      `number=='=LIKE=${searchRingNumber}=LIKE=' && user.id==${user.data?.id}`
    );
  };

  useEffect(() => {
    setRawFilters(`user.id==${user.data?.id}`);
  }, []);

  return (
    <HalfModal
      visible={props.isVisible}
      onClose={props.onClose}
      title={getText("RingDialog.Title")}
      isExpander={false}
      heightDivider={1.1}
    >
      <HBox gap="5px" alignItems="center">
        <Input
          value={addRingNumber}
          onChange={(event) => {
            setAddRingNumber(event.target.value);
          }}
          placeholder={getText("General.RingNumber")}
        />
        <Button onClick={onAddRing}>{getText("RingDialog.AddButton")}</Button>
      </HBox>
      <VBox
        Margin="10px 0 10px 0"
        width="100%"
        height="3px"
        backgroundColor={theme.palette.Grey10}
      />
      <HBox gap="5px" alignItems="center">
        <Input
          value={searchRingNumber}
          onChange={(event) => setSearchRingNumber(event.target.value)}
          placeholder={getText("RingDialog.AddRingPlaceholder")}
        />
        <Button onClick={onSearchRing}>
          {getText("RingDialog.SearchButton")}
        </Button>
      </HBox>
      <Table
        headerRow={
          <TableHeaderRow>
            <TableHeaderCell>
              {getText("RingDialog.TableHeaderRingNumber")}
            </TableHeaderCell>
            <TableHeaderCell>
              {getText("RingDialog.TableHeaderCreateDate")}
            </TableHeaderCell>
          </TableHeaderRow>
        }
      >
        {lastMeta?.isNext && (
          <TableGrowing
            type="Button"
            growing-text={getText("General.More")}
            slot="features"
            onLoadMore={() => fetchNextPage()}
          ></TableGrowing>
        )}
        {rings?.map((ring) => (
          <TableRow
            rowKey={ring.id.toString()}
            id={ring.id.toString()}
            key={ring.id}
          >
            <TableCell>{ring.number}</TableCell>
            <TableCell>{Formatter.DateTime(ring.createDate)}</TableCell>
          </TableRow>
        ))}
      </Table>
    </HalfModal>
  );
};

export default RingDialog;
