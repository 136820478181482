import { HBox, VBox } from "../../styles/Flexbox.styled";

import HighligtedText from "../HighlightedText/HighlightedText";
import Text from "../Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { breakpoints } from "../../styles/Devices";
import { getText } from "../../locales/initI18n";
import { useTheme } from "styled-components";

const FifthNews = () => {
  const theme = useTheme();
  const goTo = (url: string) => {
    window.open(url, "_blank");
  };
  return (
    <VBox width="100%">
      <HBox justifyContent="flex-end">
        <HighligtedText
          text={getText("FifthNews.PublicationDate")}
          backgroundColor={theme.palette.Yellow100}
        />
      </HBox>
      <VBox gap="20px">
        <VBox gap="10px">
          <Text
            type={TextTypeEnum.h6}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.medium}
            color={theme.palette.Grey90}
          >
            {getText("FifthNews.ExpoTitle")}
          </Text>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey70}
            textAlign="justify"
          >
            {getText("FifthNews.ExpoDescription1")}
          </Text>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.bold}
            color={theme.palette.Green70}
            textAlign="justify"
          >
            {getText("FifthNews.ExpoDescription1Caption")}
          </Text>
          <VBox
            justifyContent="center"
            alignItems="center"
            Padding="5px"
            BorderRadius="5px"
            backgroundColor={theme.palette.Grey10}
          >
            <img width="90%" src="/assets/News/FifthNewsExpoMap.png" alt="" />
            <Text
              textAlign="center"
              type={TextTypeEnum.caption}
              size={TextSizeEnum.small}
              weight={TextWeightEnum.regular}
              color={theme.palette.Grey50}
            >
              {getText("FifthNews.ExpoMapCaption")}
            </Text>
          </VBox>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey70}
            textAlign="justify"
          >
            {getText("FifthNews.ExpoDescription2")}
          </Text>
          <HBox
            Padding="20px"
            BorderRadius="10px"
            justifyContent="center"
            backgroundColor={theme.palette.Primary80}
          >
            <Text
              type={TextTypeEnum.body}
              size={TextSizeEnum.large}
              weight={TextWeightEnum.bold}
              color={theme.palette.White}
              textAlign="center"
            >
              {getText("FifthNews.ExpoDescription3")}
            </Text>
          </HBox>
        </VBox>
        <VBox gap="10px">
          <Text
            type={TextTypeEnum.h6}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.medium}
            color={theme.palette.Grey90}
          >
            {getText("FifthNews.PigeonMapTutorialTitle")}
          </Text>
          <Text
            type={TextTypeEnum.body}
            size={TextSizeEnum.medium}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey70}
            textAlign="justify"
          >
            {getText("FifthNews.PigeonMapTutorialDescripiton")}
          </Text>
          <HBox justifyContent="center">
            <iframe
              width="560"
              height="315"
              style={{ zoom: breakpoints.isPhone() ? 0.6 : 1 }}
              src="https://www.youtube.com/embed/HEJqSvcv0fU?si=tNbhfT8bSJa26sEF"
              title="Tutorial Pigeon Map"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </HBox>
        </VBox>
        <VBox gap="10px">
          <Text
            type={TextTypeEnum.h6}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.medium}
            color={theme.palette.Grey90}
          >
            {getText("FifthNews.FixesTitle")}
          </Text>
          <ul>
            <li>
              <Text
                type={TextTypeEnum.body}
                size={TextSizeEnum.medium}
                weight={TextWeightEnum.regular}
                color={theme.palette.Grey70}
                textAlign="justify"
              >
                {getText("FifthNews.Fixes1")}
              </Text>
            </li>
            <li>
              <Text
                type={TextTypeEnum.body}
                size={TextSizeEnum.medium}
                weight={TextWeightEnum.regular}
                color={theme.palette.Grey70}
                textAlign="justify"
              >
                {getText("FifthNews.Fixes2")}
              </Text>
            </li>
            <li>
              <Text
                type={TextTypeEnum.body}
                size={TextSizeEnum.medium}
                weight={TextWeightEnum.regular}
                color={theme.palette.Grey70}
                textAlign="justify"
              >
                {getText("FifthNews.Fixes3")}
              </Text>
            </li>
            <li>
              <Text
                type={TextTypeEnum.body}
                size={TextSizeEnum.medium}
                weight={TextWeightEnum.regular}
                color={theme.palette.Grey70}
                textAlign="justify"
              >
                {getText("FifthNews.Fixes4")}
              </Text>
            </li>
            <li>
              <Text
                type={TextTypeEnum.body}
                size={TextSizeEnum.medium}
                weight={TextWeightEnum.regular}
                color={theme.palette.Grey70}
                textAlign="justify"
              >
                {getText("FifthNews.Fixes5")}
              </Text>
            </li>
            <li>
              <Text
                type={TextTypeEnum.body}
                size={TextSizeEnum.medium}
                weight={TextWeightEnum.regular}
                color={theme.palette.Grey70}
                textAlign="justify"
              >
                {getText("FifthNews.Fixes6")}
              </Text>
            </li>
            <li>
              <Text
                type={TextTypeEnum.body}
                size={TextSizeEnum.medium}
                weight={TextWeightEnum.regular}
                color={theme.palette.Grey70}
                textAlign="justify"
              >
                {getText("FifthNews.Fixes7")}
              </Text>
            </li>
            <li>
              <Text
                type={TextTypeEnum.body}
                size={TextSizeEnum.medium}
                weight={TextWeightEnum.regular}
                color={theme.palette.Grey70}
                textAlign="justify"
              >
                {getText("FifthNews.Fixes8")}
              </Text>
            </li>
          </ul>
        </VBox>
      </VBox>
    </VBox>
  );
};

export default FifthNews;
