import {
  Button,
  Form,
  FormItem,
  Label,
  ObjectPage,
  ObjectPageHeader,
  ObjectPageSection,
  ObjectPageTitle,
  ObjectStatus,
  Option,
  Select,
  Switch,
  Text,
  Toolbar,
  ToolbarButton,
} from "@ui5/webcomponents-react";

import DateHelper from "../../utils/DateHelper";
import Egg from "../../models/Egg";
import { EggState } from "../../models/enums/EggStateEnum";
import HalfModal from "../HalfModal/HalfModal";
import { ModalProps } from "./IModalOptions";
import SwitchDesign from "@ui5/webcomponents/dist/types/SwitchDesign";
import WithEggHandlerProps from "../../hoc/withEggHandler/withEggHandlerProps";
import { getText } from "../../locales/initI18n";
import useEgg from "../../hooks/useEgg";
import { useNavigate } from "react-router-dom";
import useToast from "../../hooks/useToast";
import withEggHandler from "../../hoc/withEggHandler/withEggHandler";

export interface EggInformationProps extends ModalProps<undefined> {
  eggId: string;
}

const EggInformation = (props: EggInformationProps & WithEggHandlerProps) => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { egg } = useEgg(props.eggId);
  if (!egg.pigeonPair.male) return null;

  const getStatusState = () => {
    switch (egg.status) {
      case EggState.Hatched:
        return "Positive";
      case EggState.Fertile:
        return "Information";
      case EggState.Infertile:
        return "Negative";
      case EggState.Broken:
        return "Negative";
      case EggState.Dead:
        return "Negative";
      default:
        return "None";
    }
  };

  return (
    <HalfModal
      isExpander={false}
      heightDivider={1.1}
      Padding="30px 0 10px 0"
      onClose={props.onClose}
      visible
    >
      <ObjectPage
        headerArea={
          <ObjectPageHeader>
            <Form labelSpan="S12 M12 L12 XL12" layout="S1 M2 L3 XL3">
              {egg.pigeon && (
                <FormItem
                  labelContent={
                    <Label showColon>{getText("General.RingNumber")}</Label>
                  }
                >
                  <ObjectStatus
                    interactive
                    inverted
                    onClick={() =>
                      navigate(
                        `/dashboard/pigeons/${egg.pigeonPair.season.year}/${egg.pigeon?.id}`
                      )
                    }
                  >
                    {egg.pigeon?.ring?.number}
                  </ObjectStatus>
                </FormItem>
              )}
              <FormItem
                labelContent={
                  <Label showColon>{getText("PigeonPair.LayingDate")}</Label>
                }
              >
                <Text>{DateHelper.getFormattedDateTime(egg.dateOfLaying)}</Text>
              </FormItem>
              <FormItem
                labelContent={
                  <Label showColon>{getText("PigeonPair.Status")}</Label>
                }
              >
                {!egg.isHatched && (
                  <Select
                    onChange={(event) => {
                      const option = event.detail.selectedOption;
                      const status = option.value as EggState;
                      props.onEggChangeStatus(egg, status);
                      showToast(
                        getText("EggInformation.EggStatusChanged", {
                          status: Egg.getTranslatedStatus(status),
                        })
                      );
                    }}
                  >
                    <Option
                      selected={egg.status === EggState.Fertile}
                      value={EggState.Fertile}
                    >
                      {Egg.getTranslatedStatus(EggState.Fertile)}
                    </Option>
                    <Option
                      selected={egg.status === EggState.Infertile}
                      value={EggState.Infertile}
                    >
                      {Egg.getTranslatedStatus(EggState.Infertile)}
                    </Option>
                    <Option
                      selected={egg.status === EggState.Selled}
                      value={EggState.Selled}
                    >
                      {Egg.getTranslatedStatus(EggState.Selled)}
                    </Option>
                    <Option
                      selected={egg.status === EggState.Broken}
                      value={EggState.Broken}
                    >
                      {Egg.getTranslatedStatus(EggState.Broken)}
                    </Option>
                    <Option
                      selected={egg.status === EggState.Dead}
                      value={EggState.Dead}
                    >
                      {Egg.getTranslatedStatus(EggState.Dead)}
                    </Option>
                  </Select>
                )}
                {egg.isHatched && <Text>{egg.ReadableStatus}</Text>}
              </FormItem>
              <FormItem
                labelContent={
                  <Label showColon>{getText("PigeonPair.Brood")}</Label>
                }
              >
                <Text>{egg.brood}</Text>
              </FormItem>
            </Form>
          </ObjectPageHeader>
        }
        titleArea={
          <ObjectPageTitle
            actionsBar={
              <Toolbar design="Transparent">
                {egg.isFertile && (
                  <ToolbarButton
                    onClick={() => props.onHatchEgg(egg)}
                    design="Emphasized"
                    text={getText("HatchEgg.HatchEgg")}
                  />
                )}
                {!egg.isHatched && (
                  <ToolbarButton
                    onClick={async () => {
                      props.onRemoveEgg(egg);
                    }}
                    design="Negative"
                    icon="delete"
                  />
                )}
              </Toolbar>
            }
            header={getText("EggInformation.title")}
          >
            <ObjectStatus inverted state={getStatusState()}>
              {`${egg.ReadableStatus}`}
            </ObjectStatus>
          </ObjectPageTitle>
        }
      >
        {egg.pigeon && (
          <ObjectPageSection
            id="Pigeon"
            aria-label={getText("General.Pigeon")}
            titleText={getText("General.Pigeon")}
          >
            <Form labelSpan="S12 M12 L12 XL12" layout="S1 M2 L3 XL3">
              <FormItem
                labelContent={
                  <Label showColon>{getText("General.RingNumber")}</Label>
                }
              >
                <Text>{egg.pigeon.ring.number}</Text>
              </FormItem>
              <FormItem
                labelContent={
                  <Label showColon>{getText("General.Year")}</Label>
                }
              >
                <Text>{egg.pigeon.year}</Text>
              </FormItem>
              <FormItem
                labelContent={
                  <Label showColon>{getText("General.Owner")}</Label>
                }
              >
                <Text>{egg.pigeon.ownerName}</Text>
              </FormItem>
              <FormItem
                labelContent={
                  <Label showColon>{getText("Pigeon.state.Active")}</Label>
                }
              >
                <Switch
                  design={SwitchDesign.Graphical}
                  checked={egg.pigeon.isActive}
                  disabled
                />
              </FormItem>
              <FormItem>
                <Button
                  children={getText("EggInformation.GoToPigeon")}
                  onClick={() =>
                    navigate(
                      `/dashboard/pigeons/${egg.pigeonPair.season.year}/${egg.pigeonId}`
                    )
                  }
                />
              </FormItem>
            </Form>
          </ObjectPageSection>
        )}
        <ObjectPageSection
          id="Father"
          aria-label={getText("General.father")}
          titleText={getText("General.father")}
        >
          <Form labelSpan="S12 M12 L12 XL12" layout="S1 M2 L3 XL3">
            <FormItem
              labelContent={
                <Label showColon>{getText("General.RingNumber")}</Label>
              }
            >
              <Text>{egg.pigeonPair.male.ring.number}</Text>
            </FormItem>
            <FormItem
              labelContent={<Label showColon>{getText("General.Year")}</Label>}
            >
              <Text>{egg.pigeonPair.male.year}</Text>
            </FormItem>
            <FormItem
              labelContent={<Label showColon>{getText("General.Owner")}</Label>}
            >
              <Text>{egg.pigeonPair.male.ownerName}</Text>
            </FormItem>
            <FormItem
              labelContent={
                <Label showColon>{getText("Pigeon.state.Active")}</Label>
              }
            >
              <Switch
                design={SwitchDesign.Graphical}
                checked={egg.pigeonPair.male.isActive}
                disabled
              />
            </FormItem>
            <FormItem>
              <Button
                children={getText("EggInformation.GoToPigeon")}
                onClick={() =>
                  navigate(`/dashboard/pigeon/${egg.pigeonPair.male.id}`)
                }
              />
            </FormItem>
          </Form>
        </ObjectPageSection>
        <ObjectPageSection
          id="Mother"
          aria-label={getText("General.mother")}
          titleText={getText("General.mother")}
        >
          <Form labelSpan="S12 M12 L12 XL12" layout="S1 M2 L3 XL3">
            <FormItem
              labelContent={
                <Label showColon>{getText("General.RingNumber")}</Label>
              }
            >
              <Text>{egg.pigeonPair.female.ring.number}</Text>
            </FormItem>
            <FormItem
              labelContent={<Label showColon>{getText("General.Year")}</Label>}
            >
              <Text>{egg.pigeonPair.female.year}</Text>
            </FormItem>
            <FormItem
              labelContent={<Label showColon>{getText("General.Owner")}</Label>}
            >
              <Text>{egg.pigeonPair.female.ownerName}</Text>
            </FormItem>
            <FormItem
              labelContent={
                <Label showColon>{getText("Pigeon.state.Active")}</Label>
              }
            >
              <Switch
                design={SwitchDesign.Graphical}
                checked={egg.pigeonPair.female.isActive}
                disabled
              />
            </FormItem>
            <FormItem>
              <Button
                children={getText("EggInformation.GoToPigeon")}
                onClick={() =>
                  navigate(`/dashboard/pigeon/${egg.pigeonPair.female.id}`)
                }
              />
            </FormItem>
          </Form>
        </ObjectPageSection>
      </ObjectPage>
    </HalfModal>
  );
};

export default withEggHandler(EggInformation);
