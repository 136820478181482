import { PigeonColorEnum } from "../../../models/enums/PigeonColorEnum";
import SelectOption from "../../../models/SelectOption";
import { getText } from "../../../locales/initI18n";

const getPigeonColors = () =>
  [
    new SelectOption({
      key: PigeonColorEnum.None,
      text: getText("Pigeon.color.Unknown"),
    }),
    new SelectOption({
      key: PigeonColorEnum.White,
      text: getText("Pigeon.color.White"),
    }),
    new SelectOption({
      key: PigeonColorEnum.Black,
      text: getText("Pigeon.color.Black"),
    }),
    new SelectOption({
      key: PigeonColorEnum.Blue,
      text: getText("Pigeon.color.Blue"),
    }),
    new SelectOption({
      key: PigeonColorEnum.Dark,
      text: getText("Pigeon.color.Dark"),
    }),
    new SelectOption({
      key: PigeonColorEnum.Fawn,
      text: getText("Pigeon.color.Fawn"),
    }),
    new SelectOption({
      key: PigeonColorEnum.Red,
      text: getText("Pigeon.color.Red"),
    }),
    new SelectOption({
      key: PigeonColorEnum.Grey,
      text: getText("Pigeon.color.Grey"),
    }),
    new SelectOption({
      key: PigeonColorEnum.FawnRed,
      text: getText("Pigeon.color.FawnRed"),
    }),
    new SelectOption({
      key: PigeonColorEnum.FawnBlack,
      text: getText("Pigeon.color.FawnBlack"),
    }),
    new SelectOption({
      key: PigeonColorEnum.SpottedGrey,
      text: getText("Pigeon.color.SpottedGrey"),
    }),
    new SelectOption({
      key: PigeonColorEnum.SpottedBlue,
      text: getText("Pigeon.color.SpottedBlue"),
    }),
    new SelectOption({
      key: PigeonColorEnum.SpottedRed,
      text: getText("Pigeon.color.SpottedRed"),
    }),
    new SelectOption({
      key: PigeonColorEnum.SpottedDark,
      text: getText("Pigeon.color.SpottedDark"),
    }),
    new SelectOption({
      key: PigeonColorEnum.VaricolouredBlue,
      text: getText("Pigeon.color.VaricolouredBlue"),
    }),
    new SelectOption({
      key: PigeonColorEnum.VaricolouredDark,
      text: getText("Pigeon.color.VaricolouredDark"),
    }),
    new SelectOption({
      key: PigeonColorEnum.VaricolouredBlack,
      text: getText("Pigeon.color.VaricolouredBlack"),
    }),
    new SelectOption({
      key: PigeonColorEnum.VaricolouredRed,
      text: getText("Pigeon.color.VaricolouredRed"),
    }),
    new SelectOption({
      key: PigeonColorEnum.VaricolouredFawn,
      text: getText("Pigeon.color.VaricolouredFawn"),
    }),
    new SelectOption({
      key: PigeonColorEnum.VaricolouredGrey,
      text: getText("Pigeon.color.VaricolouredGrey"),
    }),
    new SelectOption({
      key: PigeonColorEnum.VaricolouredFawnRed,
      text: getText("Pigeon.color.VaricolouredFawnRed"),
    }),
    new SelectOption({
      key: PigeonColorEnum.VaricolouredFawnBlack,
      text: getText("Pigeon.color.VaricolouredFawnBlack"),
    }),
    new SelectOption({
      key: PigeonColorEnum.SpottedVaricolouredBlue,
      text: getText("Pigeon.color.SpottedVaricolouredBlue"),
    }),
    new SelectOption({
      key: PigeonColorEnum.SpottedVaricolouredDark,
      text: getText("Pigeon.color.SpottedVaricolouredDark"),
    }),
    new SelectOption({
      key: PigeonColorEnum.SpottedVaricolouredBlack,
      text: getText("Pigeon.color.SpottedVaricolouredBlack"),
    }),
    new SelectOption({
      key: PigeonColorEnum.SpottedVaricolouredRed,
      text: getText("Pigeon.color.SpottedVaricolouredRed"),
    }),
    new SelectOption({
      key: PigeonColorEnum.SpottedVaricolouredFawn,
      text: getText("Pigeon.color.SpottedVaricolouredFawn"),
    }),
  ].sort((a, b) => a.text.localeCompare(b.text));

export const getInitialColor = (current?: PigeonColorEnum) => {
  const option = getPigeonColors().find((option) => option.key === current);
  if (option) return option;
  return getPigeonColors()[0];
};

export default getPigeonColors;
