import { Button, ButtonType } from "../styles/Button.styled";
import { HBox, ResponsiveVBox, VBox } from "../styles/Flexbox.styled";
import { IoLogoFacebook, IoLogoYoutube } from "react-icons/io5";

import { ButtonOpacity } from "../styles/ButtonOpacity.styled";
import FifthNews from "../components/News/FifthNews";
import FirstNews from "../components/News/FirstNews";
import FourthNews from "../components/News/FourthNews";
import SecondNews from "../components/News/SecondNews";
import Text from "../components/Text/Text";
import TextSizeEnum from "../models/enums/TextSizeEnum";
import TextTypeEnum from "../models/enums/TextTypeEnum";
import TextWeightEnum from "../models/enums/TextWeightEnum";
import ThirdNews from "../components/News/ThirdNews";
import { breakpoints } from "../styles/Devices";
import { getText } from "../locales/initI18n";
import { isNewsReaded } from "../services/UserService";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTheme } from "styled-components";

const News = () => {
  const theme = useTheme();
  const news = [FirstNews, SecondNews, ThirdNews, FourthNews, FifthNews];
  const [currentNews, setCurrentNews] = useState(0);
  const navigate = useNavigate();

  const setIsReaded = async () => {
    try {
      await isNewsReaded();
      navigate("/dashboard");
    } catch (e) {
      navigate("/");
    }
  };

  const drawNews = () => {
    const displayedNews = news.slice(
      news.length - 1 - currentNews,
      news.length
    );
    const isAllDisplayed = displayedNews.length === news.length;
    return displayedNews.map((News, index) => {
      if (index > currentNews) return null;
      return (
        <>
          <HBox Padding="20px 0" visible={index === 0 && !isAllDisplayed}>
            <ButtonOpacity onClick={() => setCurrentNews(currentNews + 1)}>
              <Text
                type={TextTypeEnum.caption}
                weight={TextWeightEnum.regular}
                size={TextSizeEnum.large}
                color={theme.palette.Grey50}
                textDecoration="underline"
              >
                {getText("News.ShowPrevNews")}
              </Text>
            </ButtonOpacity>
          </HBox>
          <HBox
            Margin="30px 0 30px 0"
            width="50%"
            height="5px"
            backgroundColor={theme.palette.Primary50}
            BorderRadius="20px"
            visible={index !== 0}
          />
          <News key={index} />
        </>
      );
    });
  };
  return (
    <VBox
      width="100%"
      alignItems="center"
      justifyContent="flex-start"
      backgroundColor={theme.palette.White}
      overflowY="scroll"
      overflowX="hidden"
      Padding="20px 0"
    >
      <ResponsiveVBox
        backgroundColor={theme.palette.White}
        RMinWidthSM="800px"
        minWidth="100%"
        BorderRadius="20px"
        alignItems="center"
        Padding="20px"
        gap="10px"
        maxWidth="480px"
        Margin="0 5px"
      >
        <VBox width="100%">
          <Text
            type={TextTypeEnum.h3}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.medium}
            color={theme.palette.Grey90}
            textAlign="center"
            as="h1"
          >
            {getText("News.Title")}
          </Text>
          <Text
            type={TextTypeEnum.caption}
            size={TextSizeEnum.large}
            weight={TextWeightEnum.regular}
            color={theme.palette.Grey90}
            textAlign="center"
            as="h2"
          >
            {getText("News.WhatNews")}
          </Text>
          <HBox
            width="100%"
            height="3px"
            backgroundColor={theme.palette.Primary30}
          />
        </VBox>
        <VBox alignItems="center">{drawNews()}</VBox>
        <HBox justifyContent="space-evenly" width="100%" Margin="0 0 50px 0">
          <HBox width="50%" justifyContent="center">
            <a
              href="https://www.facebook.com/digging.skrzypnik"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <VBox alignItems="center">
                <IoLogoFacebook size="48px" color={theme.palette.Primary100} />
                <Text
                  size="small"
                  type="caption"
                  color={theme.palette.Grey50}
                  weight="regular"
                >
                  digging.skrzypnik
                </Text>
              </VBox>
            </a>
          </HBox>
          <HBox width="50%" justifyContent="center">
            <a
              href="https://www.youtube.com/@diggingtv-pigeonmap"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <VBox alignItems="center">
                <IoLogoYoutube size="48px" color={theme.palette.Red100} />
                <Text
                  textAlign="center"
                  size="small"
                  type="caption"
                  color={theme.palette.Grey50}
                  weight="regular"
                >
                  Digging Tv - gołębie pocztowe Pigeon Map
                </Text>
              </VBox>
            </a>
          </HBox>
        </HBox>
        <HBox
          position="absolute"
          Bottom="0"
          width={breakpoints.isPhone() ? "100%" : "30%"}
          BorderRadius={breakpoints.isPhone() ? "0" : "20px 20px 0 0"}
          justifyContent="center"
          Margin="10px 0 0 0"
          shadow="small"
          backgroundColor={theme.palette.Primary80}
        >
          <Button
            height="auto"
            width="100%"
            padding="20px 0"
            buttonType={ButtonType.Primary}
            onClick={setIsReaded}
          >
            {getText("News.GoToPigeonMap")}
          </Button>
        </HBox>
      </ResponsiveVBox>
    </VBox>
  );
};

export default News;
