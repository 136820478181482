import ITextInput, {
  TextInputState,
  TextInputType,
} from "../styles/ITextInput";
import {
  Input,
  InputDomRef,
  Label,
  Text,
  TextArea,
  TextAreaDomRef,
  Ui5CustomEvent,
} from "@ui5/webcomponents-react";
import React, { memo } from "react";

import { VBox } from "../styles/Flexbox.styled";
import { IInputSuggestionItemSelectable } from "@ui5/webcomponents/dist/Input";

const TextInput: React.FC<ITextInput> = ({
  state = TextInputState.Default,
  inputType = TextInputType.Text,
  isErrorState = false,
  errors = [],
  icon,
  children,
  ...rest
}) => {
  if (inputType === TextInputType.TextArea) {
    <VBox width="100%">
      {children && <Label>{children}</Label>}
      <TextArea
        {...rest}
        onChange={undefined}
        valueState={
          state === TextInputState.Error || isErrorState ? "Negative" : "None"
        }
        valueStateMessage={<Text>{errors.join(", ")}</Text>}
        style={{ width: "100%" }}
        onInput={(event: Ui5CustomEvent<TextAreaDomRef>) =>
          rest?.onChange?.(event.target.value)
        }
      ></TextArea>
    </VBox>;
  }
  return (
    <VBox width="100%">
      {children && <Label>{children}</Label>}
      <Input
        {...rest}
        onChange={undefined}
        valueState={
          state === TextInputState.Error || isErrorState ? "Negative" : "None"
        }
        valueStateMessage={<Text>{errors.join(", ")}</Text>}
        style={{ width: "100%" }}
        onSelectionChange={(event) => {
          rest.onSelectionChange?.(event);
          const item = event.detail.item as IInputSuggestionItemSelectable;
          if (!item) return;
          rest?.onChange?.(item.text || "");
        }}
        onInput={(event: Ui5CustomEvent<InputDomRef>) => {
          rest?.onChange?.(event.target.value);
        }}
      >
        {rest.suggestions}
      </Input>
    </VBox>
  );
};

export default memo(TextInput, (prevProps, nextProps) => {
  return (
    prevProps.value === nextProps.value &&
    prevProps.state === nextProps.state &&
    prevProps.isErrorState === nextProps.isErrorState &&
    prevProps.errors === nextProps.errors &&
    prevProps.placeholder === nextProps.placeholder &&
    prevProps.icon === nextProps.icon &&
    prevProps.children === nextProps.children &&
    prevProps.suggestions === nextProps.suggestions
  );
});
