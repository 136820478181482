import { HBox, VBox } from "../../styles/Flexbox.styled";

import Formatter from "../../utils/Formatter";
import { MapLayerEnum } from "../../models/enums/MapLayerEnum";
import { MapLayerLegendProps } from "./IMapLayerLegend";
import Text from "../Text/Text";
import TextSizeEnum from "../../models/enums/TextSizeEnum";
import TextTypeEnum from "../../models/enums/TextTypeEnum";
import TextWeightEnum from "../../models/enums/TextWeightEnum";
import { useTheme } from "styled-components";
import { breakpoints } from "../../styles/Devices";

const TempLegendText = ({ children }: { children: string }) => {
  const theme = useTheme();
  return (
    <Text
      type={
        (breakpoints.isPhone()
          ? TextTypeEnum.footer
          : TextTypeEnum.caption) as TextTypeEnum.caption
      }
      size={breakpoints.isPhone() ? TextSizeEnum.medium : TextSizeEnum.small}
      weight={
        breakpoints.isPhone() ? TextWeightEnum.regular : TextWeightEnum.medium
      }
      color={theme.palette.Grey60}
    >
      {children}
    </Text>
  );
};

const MapLayerLegend = ({ layer, visible = true }: MapLayerLegendProps) => {
  const theme = useTheme();
  if (!visible) return null;
  if (layer === MapLayerEnum.Wind) {
    return (
      <VBox
        position="absolute"
        Top="0"
        Left="0"
        width={breakpoints.isPhone() ? "60px" : "80px"}
        height="180px"
        backgroundColor={theme.palette.White}
        BorderRadius="0 0 5px 0"
        zIndex={1}
      >
        <HBox gap="5px" alignItems="center">
          <HBox
            backgroundColor="rgb(68, 1, 84)"
            width={breakpoints.isPhone() ? "5px" : "10px"}
            height="20px"
          />
          <TempLegendText>{Formatter.WindSpeed(0, false)}</TempLegendText>
        </HBox>
        <HBox gap="5px" alignItems="center">
          <HBox
            backgroundColor="rgb(71, 44, 122)"
            width={breakpoints.isPhone() ? "5px" : "10px"}
            height="20px"
          />
          <TempLegendText>{Formatter.WindSpeed(10, false)}</TempLegendText>
        </HBox>
        <HBox gap="5px" alignItems="center">
          <HBox
            backgroundColor="rgb(59, 81, 139)"
            width={breakpoints.isPhone() ? "5px" : "10px"}
            height="20px"
          />
          <TempLegendText>{Formatter.WindSpeed(20, false)}</TempLegendText>
        </HBox>
        <HBox gap="5px" alignItems="center">
          <HBox
            backgroundColor="rgb(44, 113, 142)"
            width={breakpoints.isPhone() ? "5px" : "10px"}
            height="20px"
          />

          <TempLegendText>{Formatter.WindSpeed(30, false)}</TempLegendText>
        </HBox>
        <HBox gap="5px" alignItems="center">
          <HBox
            backgroundColor="rgb(33, 144, 141)"
            width={breakpoints.isPhone() ? "5px" : "10px"}
            height="20px"
          />
          <TempLegendText>{Formatter.WindSpeed(35, false)}</TempLegendText>
        </HBox>
        <HBox gap="5px" alignItems="center">
          <HBox
            backgroundColor="rgb(39, 173, 129)"
            width={breakpoints.isPhone() ? "5px" : "10px"}
            height="20px"
          />
          <TempLegendText>{Formatter.WindSpeed(45, false)}</TempLegendText>
        </HBox>
        <HBox gap="5px" alignItems="center">
          <HBox
            backgroundColor="rgb(92, 200, 99)"
            width={breakpoints.isPhone() ? "5px" : "10px"}
            height="20px"
          />
          <TempLegendText>{Formatter.WindSpeed(55, false)}</TempLegendText>
        </HBox>
        <HBox gap="5px" alignItems="center">
          <HBox
            backgroundColor="rgb(170, 220, 50)"
            width={breakpoints.isPhone() ? "5px" : "10px"}
            height="20px"
          />
          <TempLegendText>{Formatter.WindSpeed(65, false)}</TempLegendText>
        </HBox>
        <HBox gap="5px" alignItems="center">
          <HBox
            backgroundColor="rgb(253, 231, 37)"
            width={breakpoints.isPhone() ? "5px" : "10px"}
            height="20px"
          />
          <TempLegendText>{Formatter.WindSpeed(75, false)}</TempLegendText>
        </HBox>
      </VBox>
    );
  }
  if (layer === MapLayerEnum.Temperature) {
    return (
      <VBox
        position="absolute"
        Top="0"
        Left="0"
        width={breakpoints.isPhone() ? "42px" : "50px"}
        height={breakpoints.isPhone() ? "120px" : "240px"}
        backgroundColor={theme.palette.White}
        BorderRadius="0 0 5px 0"
        zIndex={1}
      >
        <HBox
          gap="5px"
          alignItems="center"
          height={breakpoints.isPhone() ? "10px" : "20px"}
        >
          <HBox
            backgroundColor="rgb(115, 70, 105)"
            width={breakpoints.isPhone() ? "5px" : "10px"}
            height={breakpoints.isPhone() ? "10px" : "20px"}
          />
          <TempLegendText>-70°C</TempLegendText>
        </HBox>
        <HBox
          gap="5px"
          alignItems="center"
          height={breakpoints.isPhone() ? "10px" : "20px"}
        >
          <HBox
            backgroundColor="rgb(162, 70, 145)"
            width={breakpoints.isPhone() ? "5px" : "10px"}
            height={breakpoints.isPhone() ? "10px" : "20px"}
          />
          <TempLegendText>-55°C</TempLegendText>
        </HBox>
        <HBox
          gap="5px"
          alignItems="center"
          height={breakpoints.isPhone() ? "10px" : "20px"}
        >
          <HBox
            backgroundColor="rgb(143, 89, 169)"
            width={breakpoints.isPhone() ? "5px" : "10px"}
            height={breakpoints.isPhone() ? "10px" : "20px"}
          />
          <TempLegendText>-40°C</TempLegendText>
        </HBox>
        <HBox
          gap="5px"
          alignItems="center"
          height={breakpoints.isPhone() ? "10px" : "20px"}
        >
          <HBox
            backgroundColor="rgb(202, 172, 195)"
            width={breakpoints.isPhone() ? "5px" : "10px"}
            height={breakpoints.isPhone() ? "10px" : "20px"}
          />
          <TempLegendText>-25°C</TempLegendText>
        </HBox>
        <HBox
          gap="5px"
          alignItems="center"
          height={breakpoints.isPhone() ? "10px" : "20px"}
        >
          <HBox
            backgroundColor="rgb(157, 219, 217)"
            width={breakpoints.isPhone() ? "5px" : "10px"}
            height={breakpoints.isPhone() ? "10px" : "20px"}
          />
          <TempLegendText>-15°C</TempLegendText>
        </HBox>
        <HBox
          gap="5px"
          alignItems="center"
          height={breakpoints.isPhone() ? "10px" : "20px"}
        >
          <HBox
            backgroundColor="rgb(106, 191, 181)"
            width={breakpoints.isPhone() ? "5px" : "10px"}
            height={breakpoints.isPhone() ? "10px" : "20px"}
          />
          <TempLegendText>-8°C</TempLegendText>
        </HBox>
        <HBox
          gap="5px"
          alignItems="center"
          height={breakpoints.isPhone() ? "10px" : "20px"}
        >
          <HBox
            backgroundColor="rgb(100, 166, 189)"
            width={breakpoints.isPhone() ? "5px" : "10px"}
            height={breakpoints.isPhone() ? "10px" : "20px"}
          />
          <TempLegendText>-4°C</TempLegendText>
        </HBox>
        <HBox
          gap="5px"
          alignItems="center"
          height={breakpoints.isPhone() ? "10px" : "20px"}
        >
          <HBox
            backgroundColor="rgb(68, 125, 99)"
            width={breakpoints.isPhone() ? "5px" : "10px"}
            height={breakpoints.isPhone() ? "10px" : "20px"}
          />
          <TempLegendText>0°C</TempLegendText>
        </HBox>
        <HBox
          gap="5px"
          alignItems="center"
          height={breakpoints.isPhone() ? "10px" : "20px"}
        >
          <HBox
            backgroundColor="rgb(128, 147, 24)"
            width={breakpoints.isPhone() ? "5px" : "10px"}
            height={breakpoints.isPhone() ? "10px" : "20px"}
          />
          <TempLegendText>10°C</TempLegendText>
        </HBox>
        <HBox
          gap="5px"
          alignItems="center"
          height={breakpoints.isPhone() ? "10px" : "20px"}
        >
          <HBox
            backgroundColor="rgb(243, 183, 4)"
            width={breakpoints.isPhone() ? "5px" : "10px"}
            height={breakpoints.isPhone() ? "10px" : "20px"}
          />
          <TempLegendText>20°C</TempLegendText>
        </HBox>
        <HBox
          gap="5px"
          alignItems="center"
          height={breakpoints.isPhone() ? "10px" : "20px"}
        >
          <HBox
            backgroundColor="rgb(232, 83, 25)"
            width={breakpoints.isPhone() ? "5px" : "10px"}
            height={breakpoints.isPhone() ? "10px" : "20px"}
          />
          <TempLegendText>30°C</TempLegendText>
        </HBox>
        <HBox
          gap="5px"
          alignItems="center"
          height={breakpoints.isPhone() ? "10px" : "20px"}
        >
          <HBox
            backgroundColor="rgb(71, 14, 0)"
            width={breakpoints.isPhone() ? "5px" : "10px"}
            height={breakpoints.isPhone() ? "10px" : "20px"}
          />
          <TempLegendText>45°C</TempLegendText>
        </HBox>
      </VBox>
    );
  }
  return null;
};

export default MapLayerLegend;
