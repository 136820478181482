import { useRef, useState } from "react";

import Debounce from "../utils/Debounce";
import PageOptions from "../models/PageOptions";
import { MessageStrip, SuggestionItem } from "@ui5/webcomponents-react";
import TextInput from "./TextInput";
import { getRings } from "../services/RingService";
import { getText } from "../locales/initI18n";
import useUser from "../hooks/useUser";
import { getPigeonByRing } from "../services/PigeonService";
import { VBox } from "../styles/Flexbox.styled";

interface RingNumberInputProps {
  [key: string]: any;

  withLabel?: boolean;
  onChange?: (value: string) => void;
}

const RingNumberInput = (props: RingNumberInputProps) => {
  const user = useUser();
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [ringSuggestions, setRingSuggestions] = useState<string[]>([]);

  const onChangeRing = async (value: string) => {
    if (value.length < 3) {
      setRingSuggestions([]);
      return;
    }
    const options = new PageOptions({
      page: 1,
      take: 5,
      filters: `number=='=LIKE=${value}=LIKE=' && user.id==${user.data?.id}`,
    });
    const rings = await getRings(options);
    const ringSuggestions = rings.data.map((ring) => ring.number);
    setRingSuggestions(ringSuggestions);
  };

  const ringDebounce = useRef<Debounce>(new Debounce(onChangeRing, 500));
  return (
    <VBox>
      <TextInput
        {...props}
        onBlur={async (e: unknown) => {
          const ring = await getPigeonByRing(props.value);
          setIsDuplicate(!ring.isNew());
          props.onBlur?.(e);
        }}
        onChange={(value) => {
          ringDebounce.current.exec(value);
          props.onChange?.(value);
        }}
        placeholder="PL-0000-00-0000"
        showSuggestions={true}
        suggestions={ringSuggestions.map((ring) => (
          <SuggestionItem
            key={`RingNumberInput-ringSuggetions-${ring}`}
            text={ring}
          />
        ))}
      >
        {props.withLabel !== false && getText("General.RingNumber")}
      </TextInput>
      {isDuplicate && (
        <MessageStrip design="Critical" hideCloseButton>
          {getText("AddPigeonForm.form.DuplicatePigeonWarning")}
        </MessageStrip>
      )}
    </VBox>
  );
};

export default RingNumberInput;
